.chat {
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem auto 3.25rem;

  &.readonly {
    grid-template-rows: 3rem auto;
  }
}

.chat-menu {
  grid-row: 1;
  grid-column: span 3;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.chat-messages {
  background: rgba(127, 127, 127, 0.1);
  grid-row: 2;
  grid-column: span 3;
  padding: 0.5rem;
  overflow-y: scroll;
  // Hide scrollbar
  // Hide scrollbar
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.chat-message {
  margin-top: 0.5rem;
}

.chat-message-title {
  font-size: 0.75rem;
}

.chat-message-rank,
.chat-message-name {
  color: #f50057;
  font-weight: bold;
}

.chat-message-rank-icon {
  width: 0.75rem;
  height: 0.75rem;
  display: inline-block;
}

.chat-footer {
  grid-row: 3;
  grid-column: 1;
  padding: 0.5rem;
  background: rgba(127, 127, 127, 0.2);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 1rem;

  // &.disabled {
  //   display: block;
  // }

  .chat-input {
    grid-row: 1;
    grid-column: span 2;
  }

  .chat-button {
    grid-row: 1;
    grid-column: 3;
  }

  // .chat-footer-blockmessage {
  //   font-size: .75rem;
  //   font-style: italic;
  // }
}

.chat--hidden {
  .chat-messages,
  .chat-footer {
    display: none;
  }
}
